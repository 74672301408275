<!--
  - Website: https://www.4myth.com
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Copyright © 2016-2022 All rights reserved.
  - MyTh Ahmed Fayez
  -->

<template>
  <AppLayout>
    <v-container
      class="fill-height"
      fluid
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          class="text-center"
          cols="12"
          md="6"
        >
          <v-card>
            <svg
              :height="svgSize"
              :width="svgSize"
              viewBox="0 0 256 256"
              xml:space="preserve"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                style=""
                transform="translate(128 128) scale(0.72 0.72)"
              >
                <g
                  style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;"
                  transform="translate(-175.05 -175.05000000000004) scale(3.89 3.89)"
                >
                  <path
                    d="M 45 81.889 c -11.691 0 -21.202 -9.511 -21.202 -21.201 S 33.309 39.485 45 39.485 c 11.69 0 21.202 9.512 21.202 21.202 S 56.69 81.889 45 81.889 z M 45 41.485 c -10.588 0 -19.202 8.614 -19.202 19.202 S 34.412 79.889 45 79.889 s 19.202 -8.613 19.202 -19.201 S 55.588 41.485 45 41.485 z"
                    stroke-linecap="round"
                    style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;"
                    transform=" matrix(1 0 0 1 0 0) "
                  />
                  <path
                    d="M 71.227 68.699 H 63.95 v -2 h 7.276 C 80.476 66.699 88 59.175 88 49.926 s -7.524 -16.774 -16.773 -16.774 h -1.229 l -0.103 -0.885 C 68.433 19.636 57.73 10.111 45 10.111 s -23.433 9.525 -24.895 22.156 l -0.102 0.885 h -1.229 C 9.525 33.152 2 40.677 2 49.926 s 7.525 16.773 16.774 16.773 h 7.276 v 2 h -7.276 C 8.422 68.699 0 60.277 0 49.926 c 0 -10.172 8.131 -18.48 18.235 -18.766 C 20.196 17.958 31.55 8.111 45 8.111 s 24.804 9.847 26.766 23.049 C 81.869 31.445 90 39.754 90 49.926 C 90 60.277 81.578 68.699 71.227 68.699 z"
                    stroke-linecap="round"
                    style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;"
                    transform=" matrix(1 0 0 1 0 0) "
                  />
                  <path
                    d="M 52.972 69.616 c -0.256 0 -0.512 -0.098 -0.707 -0.293 L 36.407 53.466 c -0.391 -0.391 -0.391 -1.023 0 -1.414 s 1.023 -0.391 1.414 0 l 15.858 15.857 c 0.391 0.391 0.391 1.023 0 1.414 C 53.483 69.519 53.228 69.616 52.972 69.616 z"
                    stroke-linecap="round"
                    style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;"
                    transform=" matrix(1 0 0 1 0 0) "
                  />
                  <path
                    d="M 37.114 69.616 c -0.256 0 -0.512 -0.098 -0.707 -0.293 c -0.391 -0.391 -0.391 -1.023 0 -1.414 l 15.858 -15.857 c 0.391 -0.391 1.023 -0.391 1.414 0 s 0.391 1.023 0 1.414 L 37.821 69.323 C 37.625 69.519 37.37 69.616 37.114 69.616 z"
                    stroke-linecap="round"
                    style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;"
                    transform=" matrix(1 0 0 1 0 0) "
                  />
                </g>
              </g>
            </svg>

            <h1>{{ $t('messages.404') }}</h1>
            <app-btn
              :to="url"
              class="my-5"
            >
              {{ $t('home') }}
            </app-btn>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </AppLayout>
</template>

<script>

import AppLayout from '@/layouts/AppLayout'

export default {
  name: 'FourOuFour',
  components: { AppLayout },
  computed: {
    url () {
      // return this.route(this.isLogin ? this.APP_ROUTES.user.home : this.APP_ROUTES.homePage)
      return this.route(this.isAdminUser ? this.APP_ROUTES.user.home : this.APP_ROUTES.homePage)
    },
    svgSize () {
      return (this.AppIsSmall ? 200 : 350) + 'px'
    }
  },
  mounted () {
    // console.log(123)
  }
}
</script>
